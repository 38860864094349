import axios from "axios";

const APP_API_URL = process.env.REACT_APP_TOKEN_URL;
const APP_CLIENT = process.env.REACT_APP_CLIENT;

const HttpClient = () => {
  const httpClient = axios.create({
    baseURL: APP_API_URL,
  });

  httpClient.defaults.headers.common["clientname"] = APP_CLIENT;

  httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
  return httpClient;
};

export default HttpClient;
